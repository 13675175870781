import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF
} from './Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-8,8,-7,9);
makeResponsive(graph);
graph.suspendUpdate();
graph.options.layer['image']=26;
graph.options.layer['text']=2;
graph.options.layer['html']=2;
graph.options.layer['line']=2;
graph.options.layer['point']=10;
graph.options.layer['circle']=1;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
/****************PUT TITLE ****************************/
var question = placeQuestion(graph, 'dx/dt at the current location of P is &mdash;');
var comment = placeComment(graph, 'where &omega; = d&alpha;/dt');
/**********************PUT AXES **********************/
var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[0].setAttribute({name:'X'});
//ax[1].setAttribute({name:'Y'});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/***************************************************/
var index_selected_answer = -1;
const PtmList = [-1/3, -1/6, 0, 1/6, 1/3];
const answers = [['&omega;&radic;3', '-2&omega;&radic;3', '+2&omega;', '+2&omega;&radic;3'], ['-2&omega;', '-&omega;', '+2&omega;', '+&omega;'], ['-2&omega;', '0', '+2&omega;', '+&omega;'],['-2&omega;', '-&omega;', '+2&omega;', '+&omega;'], ['&omega;&radic;3', '-2&omega;&radic;3', '+2&omega;', '+2&omega;&radic;3'],['&omega;&radic;3', '2&omega;', '-2&omega;', '-4&omega;'],['&omega;&radic;3', '2&omega;','-4&omega;','2&omega;&radic;3'], ['&omega;&radic;3', '2&omega;', '-2&omega;', '-4&omega;']];
const index_right_answer = [3,2,1,0,1];
///////////////////////////////////////////////////////////////
var Pt1 = drawPoint(graph, 0.0, 0.0);
Pt1.setAttribute({fillColor:'white', strokeColor:'black'});
setSize(graph, Pt1, 4);
labelIt(graph, Pt1, '');
//////////////////////////////////////////////////////////////////
var Pt2 = drawPoint(graph, 4.0, 0.0);
setSize(graph, Pt2, 0);
Pt2.setAttribute({fillColor:'white', strokeColor:'black'});
labelIt(graph, Pt2, '');
///////////////////////////////////////////////////////////
var Ptm = drawPoint(graph, PtmList[0], 8);
Ptm.setAttribute({visible:false});
//////////////////////////////////////////////////////
var circ =drawCircle(graph, Pt1, Pt2);
circ.setAttribute({fillOpacity:1})
//////////////////////////////////////////////////////
var Pt4 = drawPoint(graph, 6.0*Math.cos(0.175),  -6.0*Math.sin(0.175));
setSize(graph, Pt4, 0);
var Pt6 = drawPoint(graph, 6.0*Math.cos(0.175),  6.0*Math.sin(0.175));
setSize(graph, Pt6, 0);
//////////////////////////////////////
placeImage(graph, '/assets/arrowUp.svg', 3, 3);
placeImage(graph, '/assets/arrowDown.svg', -4, -4);
////////////////////////////
var Pts = drawMarker(graph, circ, ()=>4.*Math.cos(Math.PI*Ptm.X()), ()=>4.*Math.sin(Math.PI*Ptm.X()));
Pts.setAttribute({fillColor:'white', strokeColor:'black'});
setSize(graph, Pts, 4);
labelIt(graph, Pts, 'P (x, y)');
///////////////////////////////////////
drawArrow(graph, Pt1, Pts);
var alpha =drawAngle(graph, Pt2, Pt1, Pts);
var PtsX = drawPoint(graph, ()=>Pts.X(), 0);
PtsX.setAttribute({visible:false});
var PtsY = drawPoint(graph, 0, ()=>Pts.Y());
PtsY.setAttribute({visible:false});
var Xaxis =drawSegment(graph, Pts, PtsX);
Xaxis.setAttribute({dash:1});
var Yaxis =drawSegment(graph, Pts, PtsY);
Yaxis.setAttribute({dash:1});
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
/***************************BUTTON MODULES******************/
var test =placeTest(graph);
hoverMe(graph, test, 'Next Question', -20, 0);
/***************************************/
var i=0; var k=0; var vis=false;
///////////////////////////////////////////////////////////////
var ansList = ['&omega;&radic;3', '-2&omega;&radic;3', '+2&omega;', '+2&omega;&radic;3']
var ansArray = listAns(graph, ansList);
//////////////////////////////////////////////////////////////
var omg1 = writeHTMLText(graph, 4, 4, '&omega;');
var omg2 = writeHTMLText(graph, -4, -4, '&omega;');
/////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY, '<ol> <li>First, express x as a function of &alpha; using trigonometric relations. <br><br> </li> <li>Then take the derivative of the both sides, and use chain rule to express dx/dt in terms of radius of the disc and &omega; </li></ol>');
hint.setAttribute({visible:false, color:'white'});
hide[0].setAttribute({visible:false});
/**************************TEST BUTTON ***********************/
test.on('down', function()
{
      if(index_selected_answer == index_right_answer[i]&& yourTotal<PtmList.length)
	  { //is correct
        check[i].setAttribute({visible:true});
       // cross[i].setAttribute({visible:false});
        yourScore = yourScore+1;
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<PtmList.length && index_selected_answer != -1)
      { //is incorrect
        //check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
		yourWrong = yourWrong+1;

      }
	  else if (index_selected_answer == -1 && yourTotal<PtmList.length)
	  {   exclaim[i].setAttribute({visible:true});
		  yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;

    if(i==PtmList.length-1)
    {
     //
	  //hide.forEach(function(item){item.setAttribute({visible:true})});
      //check.forEach(function(item){item.setAttribute({visible:false})});
      //cross.forEach(function(item){item.setAttribute({visible:false})});
	  //i = 0;
	  bck.setAttribute({opacity:1});
	  scoreCard.setAttribute({visible:true});
	  missedCard.setAttribute({visible:true});
      wrongCard.setAttribute({visible:true});
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  omg1.setAttribute({visible:false});
	  omg2.setAttribute({visible:false});
	  alpha.setAttribute({visible:false});
	  question.setAttribute({visible:false});
	  comment.setAttribute({visible:false});
	  hint.setAttribute({visible:false});
	  ansArray[0].forEach(function(item){item.setAttribute({visible:false})});
    }
	else
	{
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
	i=i+1;
	ansList[0]=answers[i][0];
	ansList[1]=answers[i][1];
	ansList[2]=answers[i][2];
	ansList[3]=answers[i][3];
	}
	hide[i].setAttribute({visible:false});
	ansArray[1].forEach(function(item){item.setAttribute({visible:false})});
    ansArray[0].forEach(function(item){item.setAttribute({color:'grey'})});
    Ptm.moveTo([PtmList[i], 8], 200);
	index_selected_answer = -1;
});
/*****************************SHUFFLE BUTTON ***********************/
var redo = placeShuffle(graph, 'left');
hoverMe(graph, redo, 'Start Over', 0, 0);
redo.on('down', function(){
	//INITIALIZATION
	  hide.forEach(function(item){item.setAttribute({visible:true})});
      check.forEach(function(item){item.setAttribute({visible:false})});
      cross.forEach(function(item){item.setAttribute({visible:false})});
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
      wrongCard.setAttribute({visible:false});
	  hint.setAttribute({visible:false});
	  i=0;
	  hide[0].setAttribute({visible:false});
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  bck.setAttribute({opacity:0});
	  Ptm.moveTo([PtmList[0], 8], 200);
	  ansArray[1].forEach(function(item){item.setAttribute({visible:false})});
      ansArray[0].forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  index_selected_answer = -1;
	  omg1.setAttribute({visible:true});
	  omg2.setAttribute({visible:true});
	  alpha.setAttribute({visible:true});
	  question.setAttribute({visible:true});
	  comment.setAttribute({visible:true});
});
/********************PLACE LOGO ********************/
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
edliy.on('down',
function(){
    k = toggle(k);
	  vis = toggleTF(vis);
    bck.setAttribute({opacity:k});
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
	  wrongCard.setAttribute({visible:false});
    hint.setAttribute({visible:vis});
	  question.setAttribute({visible:!vis});
	  omg1.setAttribute({visible:!vis});
	  omg2.setAttribute({visible:!vis});
	  alpha.setAttribute({visible:!vis});
	  ansArray[0].forEach(function(item){item.setAttribute({visible:!vis})});
    question.setAttribute({visible:!vis});
  	comment.setAttribute({visible:!vis});
});
graph.unsuspendUpdate();
/******************END OF FILE ***************************/
function listAns(board, obj){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2-0.5;
  const m ={
  anchorX: 'left',
  display: 'html',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  color:'grey',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(26*board.canvasWidth /800.)};
  ////////////////////////////////////////////////////////
  const letters =['a. ', 'b.  ', 'c. ', 'd.  '];
  let imgs =[];
  let txts =[];
  txts[0] =board.create('text', [positionX+0.35, positionY-1,  ()=>letters[0]+obj[0]], m);
  txts[1] =board.create('text', [positionX+0.35, positionY-2,  ()=>letters[1]+obj[1]], m);
  txts[2] =board.create('text', [positionX+0.35, positionY-3,  ()=>letters[2]+obj[2]], m);
  txts[3] =board.create('text', [positionX+0.35, positionY-4,  ()=>letters[3]+obj[3]], m);
  for(let il=0; il<=3; il++)
  {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  imgs[il]=board.create('image',['/assets/finger.svg', [positionX-0.35, positionY-il-0.35-1],[0.5,0.5]], {
    opacity:1,
    fixed:true,
    highlight:false,
    rotate:0,
    visible:false
  });
  //////////////////////////////////////////////////////////////////////////////////////////////////
  txts[il].on('down',
  function(){
    txts.forEach(function(item){item.setAttribute({color:'grey'})});
    imgs.forEach(function(item){item.setAttribute({visible:false})});
    txts[il].setAttribute({color:'black'});
    imgs[il].setAttribute({visible:true});
    index_selected_answer = il.valueOf();;
  });
  ///////////////////////////////////////////////////////////////////////////////////////////////
}
  return [txts, imgs];
}
}
}
export default Boxes;
